import React from 'react'
import { Link } from 'react-router-dom'

function ComingSoon() {
  return (
    <div>
        
    <section className="section section-single">
        <div className="section-single-inner">
            <div className="section-single-left">
            <div className="section-single-header">
                {/* Brand*/}<Link className="brand" href="index.html"><img className="brand-logo-dark" src="images/logo-text1.png" alt="" width={300} height={19} srcSet="images/logo-default-266x37.png 2x" /><img className="brand-logo-light" src="images/logo-inverse-133x19.png" alt="" width={133} height={19} srcSet="images/logo-inverse-266x37.png 2x" /></Link>
            </div>
            <div className="section-single-main">
                {/* <p className="big text-uppercase">Notre site internet arrive bientôt</p> */}
                <h2 style={{maxWidth: '13em'}}>Pour toute commande de billet de spectacle</h2>
                {/* Countdown*/}
                <div className="countdown countdown-classic" data-type="until" data-time="31 Dec 2019 16:00" data-format="dhms" data-style="short" />
                <p className="small"></p>
                <br />
                {/* <button><h5 className="font-weight-sbold mt-30 mt-md-45">Accès à la billeterie</h5></button> */}
                <Link className="button button-lg button-primary mt-md-20 mt-xxl-44 wow fadeIn" to="https://www.helloasso.com/associations/theatre-amateur-hte-vallee-de-la-bruche-tav" data-wow-delay=".05s">Accès à la billeterie</Link>
                
                {/* <form className="rd-form rd-mailform form-inline form-lg" data-form-output="form-output-global" data-form-type="subscribe" method="post" action="bat/rd-mailform.php">
                <div className="form-wrap form-wrap-icon">
                    <input className="form-input" id="subscribe-form-email" type="email" name="email" data-constraints="@Email @Required" />
                    <label className="form-label" htmlFor="subscribe-form-email">E-mail</label>
                    <div className="icon form-icon mdi mdi-email-outline" />
                </div>
                <div className="form-button">
                    <button className="button button-lg button-primary" type="submit">Subscribe</button>
                </div>
                </form> */}
                
            </div>
            <div className="section-single-footer">
                <h5 className="font-weight-sbold">Nos réseaux</h5>
                <div className="group group-sm"><Link className="link-1 link-1-social icon mdi mdi-facebook" to="https://www.facebook.com/p/Th%C3%A9%C3%A2tre-TAV-100063695937154" /></div>
            </div>
            </div>
            <div className="section-single-right d-none d-xl-flex">
            {/* <article className="thumbnail-1 bg-image vide" data-vide-bg="video/video" style={{backgroundImage: 'url(video/video.jpg)'}} data-vide-options="posterType: jpg">
                <div className="thumbnail-1-inner"><Link className="thumbnail-1-play" to="#" data-vide-toggle><span className="icon" /></Link></div>
            </article> */}
            <article className="thumbnail-1 bg-image vide"  style={{backgroundImage: 'url(images/stage-curtain-660078_1920.jpg)'}} >
                <div className="thumbnail-1-inner"><Link className="" to="#" data-vide-toggle><span className="icon" /></Link></div>
            </article> 
          
            
            </div>
        </div>
    </section>

    </div>
  )
}


export default ComingSoon;
