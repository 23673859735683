import React from 'react'
import { Link } from 'react-router-dom'
import SauveMoi from '../../components/Cards/SauveMoi.jsx'
import BellesMeres from '../../components/Cards/Candidate.jsx'

function Theater3() {
  return (
   
<div>

<section className="section section-md bg-default">
  <div className="container">
    <article className="blog-layout">
      <div className="blog-layout-main">
        <div className="blog-layout-main-item">
          <article className="post-corporate"><img className="post-corporate-image" src="images/candidate/header.jpg" alt='' width={768} height={396} />
            <ul className="post-corporate-meta">
              <li><span className="icon mdi mdi-calendar-today" />
                <time dateTime={2020}>Du 21 janvier au 14 avril 2024, de 11h45 à 19h45 </time>
              </li>
              <li><span className="icon mdi mdi-account" /><span>Eric Bouflerd</span></li>
              {/* <li><span className="icon mdi mdi-tag-outline" /><span>News</span></li> */}
            </ul>
            <div className="post-corporate-divider" />
            <h4 className="post-corporate-title">La Candidate</h4>
            <p>La joyeuse galaxie de personnages de « Panique au ministère » reprend du service. Et cette fois, c’est dans la course à l’Élysée qu’elle nous embarque ! En effet, Cécile Bouquigny a pris du grade depuis le premier opus : elle est actuellement ministre de la Jeunesse et des Sports ! Sur un coup de tête (et à trois mois de la fin de ses fonctions !) elle décide de se présenter à l’élection présidentielle…</p>
            {/* Quote Primary*/}
            <blockquote className="quote-primary">
              {/* <div className="quote-primary-text">
                <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore.</p>
              </div> */}
              <div className="quote-primary-footer">
                <svg className="quote-primary-line" width={70} height={8} viewBox="0 0 70 8" fill="none" role="presentation">
                  <path d="M0 1C5 1 5 7 9.92 7C14.84 7 14.92 1 19.85 1C24.78 1 24.85 7 29.77 7C34.69 7 34.77 1 39.71 1C44.65 1 44.71 7 49.63 7C54.55 7 54.63 1 59.57 1C64.51 1 64.57 7 69.5 7" strokeWidth="1.98" strokeMiterlimit={10} />
                </svg>
                <p className="heading-5 quote-primary-cite">Une pièce de Jean Franco et Guillaume Mélanie</p>
              </div>
            </blockquote>
            {/* <h4 className="post-corporate-title">Top 10 Animated Movies by Disney Studios</h4> */}
            {/* <p>Pellentesque quis dui varius, dapibus velit id, iaculis ipsum. Morbi ac eros feugiat, lacinia elit ut elementum turpis. Curabitur justo sapien, tempus sit amet rutrum eu, commodo eu lacus. Morbi in ligula nibh. Maecenas ut mi at odio hendrerit eleifend tempor vitae augue.</p> */}
            {/* <div className="post-corporate-gallery" data-lightgallery="group"> <Link className="post-corporate-thumbnail post-corporate-thumbnail-1" href="images/blog-post-2-original.jpg" data-lightgallery="item"><img className="post-corporate-thumbnail-image" src="images/blog-post-2-768x396.jpg" alt='' width={768} height={396} /></Link> <Link className="post-corporate-thumbnail post-corporate-thumbnail-2" href="images/blog-post-3-original.jpg" data-lightgallery="item"><img className="post-corporate-thumbnail-image" src="images/blog-post-3-278x331.jpg" alt='' width={278} height={331} /></Link> <Link className="post-corporate-thumbnail post-corporate-thumbnail-3" href="images/blog-post-4-original.jpg" data-lightgallery="item"><img className="post-corporate-thumbnail-image" src="images/blog-post-4-480x331.jpg" alt='' width={480} height={331} /></Link></div> */}
            <p> La Candidate est la suite de "Panique au Ministère". Mais, il n'est nul besoin d'avoir vu la 1ère pour comprendre la seconde</p>
            <br/>
            <img className="post-corporate-image" src="images/candidate/affiche.jpg" alt='' width={768} height={396} />
            <p className='text-center' >David REI ........................................ (eric)</p>
            <p className='text-center'>Eric BOUFFLERD ............................(cécile)</p>
            <p className='text-center'>Evelyne RAGASSE en alternance</p>
            <p className='text-center'>avec Ghyslaine BARTHEL ...............(gabrielle)</p>
            <p className='text-center'>Jérôme SUBLON ............................. (louis)</p>
            <p className='text-center'>Marie MISTLER ................................(sarah)</p>
            <p className='text-center'>Emmanuelle GUIOT...........................(samantha)</p>
            {/* <ul className="post-corporate-tags">
              <li> <Link href="#">News</Link></li>
              <li> <Link href="#">Cartoons</Link></li>
              <li> <Link href="#">disney</Link></li>
            </ul> */}
            <div className="post-corporate-divider" />
            <div className="post-corporate-footer">
              <h5 className="text-gray-500 font-weight-medium">Share this post</h5>
              <div>
                <div className="group group-sm"> <Link className="link-1 icon mdi mdi-facebook" href="#" /> <Link className="link-1 icon mdi mdi-instagram" href="#" /> <Link className="link-1 icon mdi mdi-behance" href="#" /> <Link className="link-1 icon mdi mdi-twitter" href="#" /></div>
              </div>
            </div>
          </article>
        </div>
        <div className="blog-layout-main-item">
          <h4 className="text-center text-sm-left">Related Posts</h4>
          <div className="row row-40">
            <SauveMoi/>
            <BellesMeres/>
          </div>
        </div>
        <div className="blog-layout-main-item">
          <h4>Leave a Reply</h4>
          {/* RD Mailform*/}
          <form className="rd-form rd-mailform" data-form-output="form-output-global" data-form-type="contact" method="post" action="bat/rd-mailform.php">
            <div className="row row-30">
              <div className="col-md-6">
                <div className="form-wrap form-wrap-icon">
                  <input className="form-input" id="contact-name" type="text" name="name" data-constraints="@Required" />
                  <label className="form-label" htmlFor="contact-name">Name</label>
                  <div className="icon form-icon mdi mdi-account-outline text-primary" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-wrap form-wrap-icon">
                  <input className="form-input" id="contact-email" type="email" name="email" data-constraints="@Email @Required" />
                  <label className="form-label" htmlFor="contact-email">E-mail</label>
                  <div className="icon form-icon mdi mdi-email-outline text-primary" />
                </div>
              </div>
              <div className="col-12">
                <div className="form-wrap form-wrap-icon">
                  <label className="form-label" htmlFor="contact-message">Message</label>
                  <textarea className="form-input" id="contact-message" name="message" data-constraints="@Required" defaultValue={""} />
                  <div className="icon form-icon mdi mdi-message-outline text-primary" />
                </div>
              </div>
            </div>
            <div className="form-wrap form-wrap-button">
              <button className="button button-lg button-primary" type="submit">Submit</button>
            </div>
          </form>
        </div>
      </div>
      <div className="blog-layout-aside">
        
        
        <div className="blog-layout-aside-item">
          <h5>Les pièces 2023</h5>
          <div className="group-post-minimal">
            <article className="post-minimal"> <Link className="post-minimal-media" href="blog-post.html"><img className="post-minimal-image" src="images/blog-post-5-79x78.jpg" alt='' width={79} height={78} /></Link>
              <div className="post-minimal-main">
                <p className="post-minimal-title"> <Link className="link-black" href="blog-post.html">Top 8 Facts About DC’s Shazam! Movie</Link></p>
                <time className="post-minimal-time" dateTime={2020}>May 12, 2020</time>
              </div>
            </article>
            <article className="post-minimal"> <Link className="post-minimal-media" href="blog-post.html"><img className="post-minimal-image" src="images/blog-post-6-79x78.jpg" alt='' width={79} height={78} /></Link>
              <div className="post-minimal-main">
                <p className="post-minimal-title"> <Link className="link-black" href="blog-post.html">Toy Story 4: What We Know So Far</Link></p>
                <time className="post-minimal-time" dateTime={2020}>June 19, 2020</time>
              </div>
            </article>
            <article className="post-minimal"> <Link className="post-minimal-media" href="blog-post.html"><img className="post-minimal-image" src="images/blog-post-7-79x78.jpg" alt='' width={79} height={78} /></Link>
              <div className="post-minimal-main">
                <p className="post-minimal-title"> <Link className="link-black" href="blog-post.html">Writers Share Plans for “True Detective”</Link></p>
                <time className="post-minimal-time" dateTime={2020}>July 25, 2020</time>
              </div>
            </article>
          </div>
        </div>
        <div className="blog-layout-aside-item">
          <h5>Newsletter Signup</h5>
          <form className="rd-form rd-mailform" data-form-output="form-output-global" data-form-type="subscribe" method="post" action="bat/rd-mailform.php">
            <div className="form-wrap form-wrap-icon">
              <input className="form-input" id="subscribe-form-email" type="email" name="email" data-constraints="@Email @Required" />
              <label className="form-label" htmlFor="subscribe-form-email">E-mail</label>
              <div className="icon form-icon mdi mdi-email-outline" />
            </div>
            <div className="form-wrap mt-30">
              <button className="button button-block button-lg button-primary" type="submit">subscribe</button>
            </div>
          </form>
        </div>
      </div>
        
        
        
    </article>
  </div>
</section>

    </div>
  )
}


export default Theater3