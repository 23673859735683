import { useRouteError } from "react-router-dom";

function PageError() {
    const error = useRouteError()
    console.log(error);
    return <>
     
      {/* <p>
        {error?.error?.toString() ?? error?.tostring}
      </p> */}
<div>
  <header className="section page-header page-header-1 context-dark">
    <div className="page-header-1-figure m-parallax">
      <div className="page-header-1-image m-parallax-image" style={{backgroundImage: 'url(images/not-found.jpg)'}} />
    </div>
  
    <section className="breadcrumbs-custom">
      <div className="breadcrumbs-custom-inner">
        <div className="container">
          <div className="breadcrumbs-custom-main m-parallax-content">
            <h2 className="breadcrumbs-custom-title">Page non trouvée</h2>
            <p>{error?.error?.toString() ?? error?.tostring}</p>
            {/* <div className="group group-sm group-middle"><a className="button button-sm button-icon button-icon-left button-light" data-lightgallery="item" href="https://youtu.be/VML6rQWssSk"><span className="icon mdi mdi-play" />Video Preview</a></div> */}
          </div>
        </div>
      </div>
    </section>
  </header>
  <section className="section section-md bg-default text-center">
    <div className="container">
      <article className="text-group-1">
        <p className="text-xxl text-outer">404</p>
        <h4 className="text-inner">Oops! Cette page est introuvable</h4>
      </article>
      
    </div>
  </section>
</div>



    </>
  }

  export default PageError;