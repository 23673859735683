import { Link, NavLink, Outlet, RouterProvider, createBrowserRouter, useRouteError } from "react-router-dom";
import Navbar from "./components/Navbar.jsx";
import ComingSoon from "./pages/ComingSoon.jsx";
import Home from "./pages/Home.jsx"
import PageError from "./pages/PageError.jsx";
import Theater from "./pages/Theater.jsx";
import Theater1 from "./pages/TheaterSingle/Theater1.jsx";
import Theater2 from "./pages/TheaterSingle/Theater2.jsx";
import Theater3 from "./pages/TheaterSingle/Theater3.jsx";

const router = createBrowserRouter ([
  {
    path: '/',
    element: <ComingSoon/>,
    errorElement: <PageError/>,
   
  },
  //  {
  //   path: 'home',
  //   element: <div><Home/></div>
  // },{
  //   path: 'theater1',
  //   element: <div><Theater1/></div>
  // },{
  //   path: 'theater2',
  //   element: <div><Theater2/></div>
  // },{
  //   path: 'theater3',
  //   element: <div><Theater3/></div>
  // },
  // {
  //   path: 'theater',
  //   element: <div><Theater/></div>,
  //   children: [
  //     {
  //       path: 'theater1',
  //       element: <div><Theater1/></div>
  //     },{
  //       path: 'theater2',
  //       element: <div><Theater2/></div>
  //     },{
  //       path: 'theater3',
  //       element: <div><Theater3/></div>
  //     }
  //   ]
  
  // },
  // {
  //   path: 'contact',
  //   element: <div>Contact</div>
  // },
  // {
  //   path: 'comingsoon',
  //   element: <div><ComingSoon/></div>
  // }
])



// function Root() {
//   return<>
//   <header>
//     <nav>
//       <NavLink to="/home">blog</NavLink>
//       <NavLink to="/contact">contact</NavLink>
//     </nav>
//   </header>
//   <div className="container">
//     <Outlet/>
//   </div>
//   </>
// }

function App() {
  return (
    <RouterProvider router={router}/>
  );
}

export default App;
