import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
  return (
    <div>
              {/* <footer className="section footer-modern context-dark">
  <div className="footer-modern-main">
    <div className="container">
      <div className="row row-30 justify-content-lg-between">
        <div className="col-sm-5 col-md-3 col-lg-3">
          <p className="footer-modern-title">Useful Links</p>
          <div className="footer-modern-item-block">
            <ul className="list list-1">
              <li><Link to>About</Link></li>
              <li><Link to>News</Link></li>
              <li><Link to>Team</Link></li>
              <li><Link to>EMovies App</Link></li>
            </ul>
          </div>
        </div>
        <div className="col-sm-7 col-md-5 col-lg-4">
          <p className="footer-modern-title">Categories</p>
          <div className="footer-modern-item-block" style={{maxWidth: 300}}>
            <div className="row row-13">
              <div className="col-6">
                <ul className="list list-1">
                  <li><Link to="categories.html">Movies</Link></li>
                  <li><Link to="categories.html">TV Shows</Link></li>
                  <li><Link to="categories.html">Comedy</Link></li>
                  <li><Link to="categories.html">Marvel</Link></li>
                </ul>
              </div>
              <div className="col-6">
                <ul className="list list-1">
                  <li><Link to="categories.html">Cartoons</Link></li>
                  <li><Link to="categories.html">Disney</Link></li>
                  <li><Link to="categories.html">Other</Link></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-lg-3">
          <p className="footer-modern-title">Get in Touch</p>
          <div className="footer-modern-item-block">
            <ul className="list list-1">
              <li><Link to="contact-us.html">Contact Us</Link></li>
              <li><Link to="#">Customer Support</Link></li>
              <li><Link to="#">FAQ</Link></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="footer-modern-aside">
    <div className="container">
      <div className="footer-modern-aside-layout"><Link className="brand" to="index.html"><img className="brand-logo-dark" src="images/logo-default-133x19.png" alt="" width={133} height={19} srcSet="images/logo-default-266x37.png 2x" /><img className="brand-logo-light" src="images/logo-inverse-133x19.png" alt="" width={133} height={19} srcSet="images/logo-inverse-266x37.png 2x" /></Link>
        <p className="rights"><span>©&nbsp; </span><span className="copyright-year" /><span>&nbsp;</span><span>. All rights reserved.&nbsp;</span><Link to="privacy-policy.html">Privacy Policy</Link></p>
        <div>
          <div className="group group-sm"><Link className="link-1 icon mdi mdi-instagram" to="#" /><Link className="link-1 icon mdi mdi-facebook" to="#" /><Link className="link-1 icon mdi mdi-youtube-play" to="#" /></div>
        </div>
      </div>
    </div>
  </div>
</footer> */}

    </div>
  )
}


export default Footer